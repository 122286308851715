import { Provider } from '../models/provider'
import { convertTo12HourFormat } from "../utils/helpers";

export const schedule_time_slot = {
    sunday: {
        open: false,
        array: [
            {
                from: {
                    label: '',
                    value: '',
                },
                to: {
                    label: '',
                    value: '',
                },
            },
        ],
    },
    monday: {
        open: false,
        array: [
            {
                from: {
                    label: '',
                    value: '',
                },
                to: {
                    label: '',
                    value: '',
                },
            },
        ],
    },
    tuesday: {
        open: false,
        array: [
            {
                from: {
                    label: '',
                    value: '',
                },
                to: {
                    label: '',
                    value: '',
                },
            },
        ],
    },
    wednesday: {
        open: false,
        array: [
            {
                from: {
                    label: '',
                    value: '',
                },
                to: {
                    label: '',
                    value: '',
                },
            },
        ],
    },
    thursday: {
        open: false,
        array: [
            {
                from: {
                    label: '',
                    value: '',
                },
                to: {
                    label: '',
                    value: '',
                },
            },
        ],
    },
    friday: {
        open: false,
        array: [
            {
                from: {
                    label: '',
                    value: '',
                },
                to: {
                    label: '',
                    value: '',
                },
            },
        ],
    },
    saturday: {
        open: false,
        array: [
            {
                from: {
                    label: '',
                    value: '',
                },
                to: {
                    label: '',
                    value: '',
                },
            },
        ],
    },
}
export const provider: Provider = {
    main: {
        logo: undefined,
        avatar: undefined,
        name: '',
        default_service_id: '',
        slug: undefined,
        is_accept_new_patients: false,
        without_physical_address: false,
        city: '',
        zip_code: '',
        email: '',
        phone: '',
        street: '',
        state: '',
        longitude: '',
        latitude: '',
        zipOrState: 'states',
        states: [],
        cities: [],
        header_color: '#23354D'
    },
    website: '',
    instagram_link: '',
    linkedin_link: '',
    intro_video_link: '',
    place_of_services: [],
    services: [],
    ages_comment: '',
    languages: [],
    genders: [],
    accreditations: [],
    certifications: [],
    educations: [],
    is_notify_clients: 'No',
    default_treatment_session_length: 30,
    default_evaluation_session_length: 90,
    notify_number_of_hours: 48,
    in_home_cities: [],
    other_location_text: '',
    faqs: [],
    is_enabled_waiting_list: false,
    waiting_list: [],
    founder_story: '',
    parent_perspective_description: '',
    parent_perspective_name: '',
    parent_perspective_date: '',
    description: '',
    difference_title: '',
    difference: '',
    gallery: [],
    google_review_link: '',
    yelp_review_link: '',
    schedulingIsContactForm: false,
    schedule_email: '',
    schedule_respond_within: '',
    personalInfo: {
        first_name: '',
        last_name: '',
        middle_initial: '',
        birthday_at: null,
        gusto_name: '',
        preferences: '',
        phone: '',
        email: '',
        address: '',
        city: '',
        state: '',
        zip_code: '',
        npi_number: '',
        license_state: '',
        license_number: '',
        license_expiration: null,
        additional_licenses: [],
        caqh_number: '',
        caqh_username: '',
        caqh_password: '',
        malpractice_policy: '',
        malpractice_policy_start: null,
        malpractice_policy_end: null,
        ein: '',
        clients: [],
        google_calendar_id: null
    },
    billingInsuranceInfo: {
        procedure_codes: [],
        insurance: [],
        insurance_qualifier: 'ZZ',
        taxonomy_number: '235Z00000X',
        // procedure_code: '',
        // private_pay_rate: '',
        // default_procedure_a1: '',
        // default_procedure_b1: '',
        // default_procedure_c1: '',
        // default_procedure_d1: '',
        // additional_procedures: [],
        penalty_for_cancelling: '',
        partial_penalty_for_cancelling: '',
    },
    default_intake_session_length: 30,
    intake_schedule_in_advance: 48,
    max_intakes_per_week: 0,
    schedule_time_slot: schedule_time_slot,
}

export const generateScheduleTimeSlot = (schedule_time_slot_arg) => {
    const new_schedule_time_slot = JSON.parse(JSON.stringify(schedule_time_slot));
    for (const key in new_schedule_time_slot) {
        if (schedule_time_slot_arg[key].length) {
            new_schedule_time_slot[key].open = true;
            new_schedule_time_slot[key].array = schedule_time_slot_arg[key].map(
              (slot) => ({
                  from: {
                      label: convertTo12HourFormat(slot.from),
                      value: slot.from,
                  },
                  to: {
                      label: convertTo12HourFormat(slot.to),
                      value: slot.to,
                  },
              })
            );
        }
    }
    return new_schedule_time_slot;
};

// export const provider: Provider = {
//     main: {
//         logo: undefined,
//         avatar: undefined,
//         name: 'Los Altos',
//         default_service_id: 1,
//         slug: 'aceing-autism-mountain-view-ca-los-altos-ca',
//         is_accept_new_patients: true,
//         without_physical_address: false,
//         city: 'Los Altos',
//         zip_code: '94022',
//         email: 'mountainview@aceingautism.org',
//         phone: '(310) 401-0544',
//         street: '201 Almond Ave',
//         state: 'CA',
//         longitude: '-122.1094443',
//         latitude: '37.3856281',
//         zipOrState: 'states',
//         states: [],
//         cities: [],
//         header_color: '#a3cbff '
//     },
//     website: 'https://aceingautism.org/locations/mountain-view-ca/',
//     instagram_link: 'https://instagram.com/aceingautism/',
//     linkedin_link: 'https://www.linkedin.com/company/aceing-autism/about/',
//     intro_video_link: 'https://youtu.be/BjlaaMg51Rw',
//     settings: [
//         {
//             id: 1,
//             name: "In-home",
//             slug: "in-home"
//         },
//     ],
//     services: [
//         {
//             "id": 3,
//             "name": "Speech Therapy",
//             "has_insurances": true,
//             "is_for_homepage": true,
//             "is_popular": true,
//             "is_top": true,
//             "slug": "speech-therapy",
//             "h1": "Speech Therapy ccc",
//             "seo_title": null,
//             "seo_description": null
//         },
//         {
//             "id": 5,
//             "name": "ABA Therapy",
//             "has_insurances": true,
//             "is_for_homepage": true,
//             "is_popular": true,
//             "is_top": false,
//             "slug": "aba-therapy",
//             "h1": "ABA Therapists",
//             "seo_title": "{{setting}} ABA Therapists in {{cityName}} {{zipCode}} | ABA Therapy {{state}}",
//             "seo_description": "some description"
//         },
//         {
//             "id": 6,
//             "name": "Private Schools",
//             "has_insurances": false,
//             "is_for_homepage": false,
//             "is_popular": false,
//             "is_top": false,
//             "slug": "private-school",
//             "h1": "Private Schools",
//             "seo_title": null,
//             "seo_description": null
//         }
//     ],
//     ages_comment: '12',
//     languages: [
//         {
//             "id": 7,
//             "name": "American Sign Language"
//         },
//         {
//             "id": 9,
//             "name": "Armenian"
//         }
//     ],
//     genders: [
//         {
//             "id": 1,
//             "name": "Female"
//         },
//         {
//             "id": 2,
//             "name": "Male"
//         }
//     ],
//     accreditations: [
//         {
//             "id": 5,
//             "name": "COPAA Member",
//             "description": "This advocate/attorney is a member of the Council of Parent Attorneys and Advocates (COPAA).",
//             "is_disabled_edit": false,
//             "is_show_in_search": false,
//             "is_premium": false
//         }
//     ],
//     certifications: [
//         {
//             "id": 7,
//             "name": "Pediatric Gastroenterology, American Board of Pediatrics"
//         }
//     ],
//     educations: [
//         {
//             "id": 8,
//             "name": "Florida Atlantic University, International Business"
//         }
//     ],
//     faqs: [
//         {
//             "title": "question",
//             "text": "<p>answer</p>",
//             "is_editable": true
//         }
//     ],
//     is_enabled_waiting_list: true,
//     waiting_list: [
//         {
//             "service_id": "3",
//             "pm_weeks": "34",
//             "am_weeks": "12",
//             "description": "description"
//         }
//     ],
//     founder_story: 'Founder Story',
//     parent_perspective_description: '<p>Parent Perspective</p>',
//     parent_perspective_name: 'Parent Name',
//     parent_perspective_date: '12.02.2023',
//     description: '<p>Description</p>',
//     difference_title: 'Difference Title',
//     difference: 'Difference Description',
//     gallery: [],
//     google_review_link: 'https://stackoverflow.com',
//     yelp_review_link: 'https://stackoverflow.com',
//     schedulingIsContactForm: true,
//     schedule_email: 'hello@gmail.com',
//     schedule_respond_within: 2,
//     personalInfo: {
//         first_name: 'First Name',
//         last_name: 'Last Name',
//         middle_initial: 'Middle Initial',
//         phone: '(310) 401-0544',
//         email: 'hello@gmail.com',
//         address: 'mailing address',
//         city: 'mailing city',
//         state: 'CA',
//         zip_code: '123445',
//         npi_number: '123445',
//         license_state: 'SF',
//         license_number: '123445',
//         license_expiration: 'Sun Oct 01 2023 15:11:04 GMT+0700 (GMT+07:00)',
//         additional_licenses: [],
//         caqh_number: '12345',
//         caqh_username: 'CAQH username',
//         caqh_password: 'CAQH password',
//         malpractice_policy: '',
//         malpractice_policy_start: 'Sun Oct 01 2023 15:11:04 GMT+0700 (GMT+07:00)',
//         malpractice_policy_end: 'Sun Oct 01 2023 15:11:04 GMT+0700 (GMT+07:00)',
//         ein: '12345'
//     },
//     billingInsuranceInfo: {
//         insurance: [
//             {
//                 "id": 14,
//                 "name": "ATRIO Health Plans",
//                 "is_top": false,
//                 "slug": "atrio-health-plans"
//             }
//         ],
//         insurance_qualifier: 'CA',
//         taxonomy_number: '123456',
//         procedure_code: 'code 123',
//         penalty_for_cancelling: '123',
//         private_pay_rate: '123',
//         default_procedure_a1: 'a1',
//         default_procedure_b1: 'b1',
//         default_procedure_c1: 'c1',
//         default_procedure_d1: 'd1',
//         additional_procedures: [
//             {
//                 procedure_code_a2: 'a2',
//                 private_pay_rate_a2: '123',
//                 default_procedure_a1: 'a2',
//                 default_procedure_b1: 'b2',
//                 default_procedure_c1: 'c2',
//                 default_procedure_d1: 'd2',
//             }
//         ],
//     }
// }