import {Client, Provider} from "../models";
const {zonedTimeToUtc, utcToZonedTime, formatInTimeZone, getTimeZoneValue, getTimezoneOffset, toDate} = require("date-fns-tz");
import {parseISO, set, getUnixTime, differenceInHours, format, addDays} from 'date-fns';
import { issueReasonsList, passwordRegEx, timezone } from "./stateValues";
import * as yup from "yup";
import {generateScheduleTimeSlot, schedule_time_slot as schedule_time_slot_data} from "../data";

export function convertTo12HourFormat(time24: string){
  if (!time24) return undefined;
  if (time24.length > 5) return time24;
  // Parse the input time string
  const [hours, minutes] = time24.split(':');
  let period = 'AM';
  // Convert hours to 12-hour format and set the period
  let hours12 = parseInt(hours, 10);
  if (hours12 >= 12) {
    period = 'PM';
    hours12 = hours12 === 12 ? 12 : hours12 - 12;
  } else {
    hours12 = hours12 === 0 ? 12 : hours12;
  }

  // Return the formatted time in 12-hour format
  return `${hours12}:${minutes}${period}`;
};

function formatDate(date) {
  return (
    [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join('-') +
    ' ' +
    [
      padTo2Digits(date.getHours()),
      padTo2Digits(date.getMinutes()),
      padTo2Digits(date.getSeconds()),
    ].join(':')
  );
}

function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

export const changeDate = (time: string) => {
  if (!time) return ''
  return formatDate(new Date(time))
}

export const formatWithTZ = (date, formatStr) => {
  if (!date) return null
  const zonedDate = parseISO(date);
  const formattedDate = format(zonedDate, formatStr);
  return formattedDate || null
}

export const doctorGenerateFormData = (payload) => {
  const {
    main: {
      avatar,
      logo,
      name,
      default_service_id,
      slug,
      is_accept_new_patients,
      without_physical_address,
      city,
      zip_code,
      email,
      phone,
      street,
      state,
      longitude,
      latitude,
      zipOrState,
      states,
      cities,
      header_color,
    },
    website,
    instagram_link,
    linkedin_link,
    intro_video_link,
    place_of_services,
    services,
    ages_comment,
    languages,
    genders,
    accreditations,
    certifications,
    educations,
    is_notify_clients,
    default_treatment_session_length,
    default_evaluation_session_length,
    in_home_cities,
    other_location_text,
    notify_number_of_hours,
    faqs,
    is_enabled_waiting_list,
    waiting_list,
    founder_story,
    parent_perspective_description,
    parent_perspective_name,
    parent_perspective_date,
    description,
    difference_title,
    difference,
    gallery,
    google_review_link,
    yelp_review_link,
    schedulingIsContactForm,
    schedule_email,
    schedule_respond_within,
    personalInfo: {
      first_name,
      last_name,
      middle_initial,
      gusto_name,
      birthday_at,
      preferences,
      phone: personalPhone,
      email: personalEmail,
      address: personalAddress,
      city: personalCity,
      state: personalState,
      zip_code: personalZipCode,
      npi_number,
      license_state,
      license_number,
      license_expiration,
      additional_licenses,
      caqh_number,
      caqh_username,
      caqh_password,
      malpractice_policy,
      malpractice_policy_start,
      malpractice_policy_end,
      ein,
      clients,
      google_calendar_id
    },
    billingInsuranceInfo: {
      procedure_codes,
      insurance,
      insurance_qualifier,
      taxonomy_number,
      // procedure_code,
      penalty_for_cancelling,
      partial_penalty_for_cancelling,
      // private_pay_rate,
      // default_procedure_a1,
      // default_procedure_b1,
      // default_procedure_c1,
      // default_procedure_d1,
      // additional_procedures,
      // internal fields
    },
    default_intake_session_length,
    intake_schedule_in_advance,
    max_intakes_per_week,
    schedule_time_slot
  } = payload

  const formData = new FormData()

  // main
  if (avatar && avatar.id) {
    formData.append('avatar', avatar.id)
  } else if (avatar && !avatar.id) {
    formData.append('avatar', avatar)
  }

  if (logo && logo.id) {
    formData.append('logo', logo.id)
  } else if (logo && !logo.id) {
    formData.append('logo', logo)
  }
  formData.append('name', name)
  formData.append('default_service_id', default_service_id)
  formData.append('slug', slug)
  formData.append('is_accept_new_patients', is_accept_new_patients ? '1' : '0')
  formData.append('without_physical_address', without_physical_address ? '1' : '0')
  formData.append('city', city)
  formData.append('zip_code', zip_code)
  formData.append('email', email)
  formData.append('phone', phone)
  formData.append('street', street)
  formData.append('state', state)
  formData.append('longitude', longitude)
  formData.append('latitude', latitude)
  formData.append('google_calendar_id', google_calendar_id)

  if (without_physical_address) {
    formData.delete('state')
    formData.delete('zip_code')
    formData.delete('street')
    formData.delete('longitude')
    formData.delete('latitude')
    formData.delete('city')
    if (zipOrState === 'states') {
      states.map(state => formData.append('states[]', state.id))
    } else if (zipOrState === 'cities') {
      cities.map(city => formData.append('cities[]', city.id))
    } else {
      formData.append('states', '')
      formData.append('cities', '')
    }
  }

  formData.append('header_color', header_color)
  formData.append('website', website)
  formData.append('instagram_link', instagram_link)
  formData.append('linkedin_link', linkedin_link)
  formData.append('intro_video_link', intro_video_link)
  place_of_services.map(place_of_service => formData.append('place_of_services[]', place_of_service.id))
  services.map(service => formData.append('services[]', service.id))
  clients.map(client => formData.append('clients[]', client.id))
  formData.append('ages_comment', ages_comment)
  languages && languages.map(language => formData.append('languages[]', language.id))
  genders.map(gender => formData.append('genders[]', gender.id))
  accreditations.map(accreditation => formData.append('accreditations[]', accreditation.id))
  certifications.map(certification => formData.append('certifications[]', certification.id))
  educations.map(education => formData.append('educations[]', education.id))
  formData.append('is_notify_clients', is_notify_clients === 'Yes' ? '1' : '0')
  formData.append('default_treatment_session_length', default_treatment_session_length)
  formData.append('default_evaluation_session_length', default_evaluation_session_length)
  formData.append('other_location_text', other_location_text)
  formData.append('notify_number_of_hours', notify_number_of_hours)
  faqs.map((faq, index) => {
    if (faq.id) {
      formData.append('faqs[' + index + '][id]', faq.id)
    }
    formData.append('faqs[' + index + '][title]', faq.title)
    formData.append('faqs[' + index + '][text]', faq.text)
  })
  in_home_cities.map(val => {
    formData.append('in_home_cities[]', val.id)
  })
  formData.append('is_enabled_waiting_list', !is_enabled_waiting_list ? '0' : '1')
  if (is_enabled_waiting_list) {
    waiting_list.forEach((val, index) => {
      if (val.id) {
        formData.append('waiting_list[' + index + '][id]', val.id)
      }
      formData.append('waiting_list[' + index + '][am_weeks]', val.am_weeks)
      formData.append('waiting_list[' + index + '][pm_weeks]', val.pm_weeks)
      formData.append('waiting_list[' + index + '][description]', val.description)
      formData.append('waiting_list[' + index + '][service_id]', val.service_id)
    })
  }
  formData.append('founder_story', founder_story)
  formData.append('parent_perspective[description]', parent_perspective_description)
  formData.append('parent_perspective[name]', parent_perspective_name)
  formData.append('parent_perspective[date]', parent_perspective_date)
  formData.append('description', description)
  formData.append('difference_title', difference_title)
  formData.append('difference', difference)
  if (gallery.length) {
    gallery.forEach((val, index) => {
      if (val.id) formData.append('gallery[' + index + ']', val.id)
      else formData.append('gallery[' + index + ']', val)
    })
  }
  formData.append('google_review_link', google_review_link)
  formData.append('yelp_review_link', yelp_review_link)

  if (schedulingIsContactForm) {
    formData.append('schedule[type]', 'contact-form')
    formData.append('schedule[data][email]', schedule_email)
    formData.append('schedule[data][respond_within]', schedule_respond_within)
  } else {
    formData.set('schedule', '')
  }

  if(procedure_codes.length){
    procedure_codes.forEach((val, index) => {
      formData.append(`procedure_codes[${index}][id]`, val.id)
      val.payout.forEach((payoutObj, payoutIndex) => {
        formData.append(`procedure_codes[${index}][payout][${payoutIndex}][id]`, payoutObj.id)
        formData.append(`procedure_codes[${index}][payout][${payoutIndex}][sum]`, payoutObj.sum)
      })
      val.charge.forEach((chargeObj, payoutIndex) => {
        formData.append(`procedure_codes[${index}][charge][${payoutIndex}][id]`, chargeObj.id)
        formData.append(`procedure_codes[${index}][charge][${payoutIndex}][sum]`, chargeObj.sum)
      })
      // val.max_charge.forEach((chargeObj, payoutIndex) => {
      //   formData.append(`procedure_codes[${index}][max_charge][${payoutIndex}][id]`, chargeObj.id)
      //   formData.append(`procedure_codes[${index}][max_charge][${payoutIndex}][sum]`, chargeObj.sum)
      // })
    })

  }

  // personal info
  formData.append('personal_info[first_name]', first_name)
  formData.append('personal_info[last_name]', last_name)
  formData.append('personal_info[middle_initial]', middle_initial)
  formData.append('personal_info[gusto_name]', gusto_name)
  formData.append('personal_info[birthday_at]', changeDate(birthday_at))
  formData.append('personal_info[preferences]', preferences)
  formData.append('personal_info[phone]', personalPhone)
  formData.append('personal_info[email]', personalEmail)
  formData.append('personal_info[address]', personalAddress)
  formData.append('personal_info[city]', personalCity)
  formData.append('personal_info[state]', personalState)
  formData.append('personal_info[zip_code]', personalZipCode)
  formData.append('personal_info[npi_number]', npi_number)
  formData.append('personal_info[license_state]', license_state)
  formData.append('personal_info[license_number]', license_number)
  formData.append('personal_info[license_expiration]', changeDate(license_expiration))

  if (additional_licenses.length) {
    additional_licenses.forEach((license, index) => {
      formData.append(`personal_info[additional_licenses][${index}][license_state]`, license.license_state)
      formData.append(`personal_info[additional_licenses][${index}][license_number]`, license.license_number)
      formData.append(`personal_info[additional_licenses][${index}][license_expiration]`, changeDate(license.license_expiration))
    })
  } else {
    formData.append(`personal_info[additional_licenses]`, '')
  }

  formData.append('personal_info[caqh_number]', caqh_number)
  formData.append('personal_info[caqh_username]', caqh_username)
  formData.append('personal_info[caqh_password]', caqh_password)
  if (malpractice_policy && typeof malpractice_policy !== 'string') {
    formData.append('personal_info[malpractice_policy]', malpractice_policy)
  } else if(!malpractice_policy) {
    formData.append('personal_info[malpractice_policy]', '')
  }
  formData.append('personal_info[malpractice_policy_start]', changeDate(malpractice_policy_start))
  formData.append('personal_info[malpractice_policy_end]', changeDate(malpractice_policy_end))
  formData.append('personal_info[ein]', ein)
  // billing insurance
  insurance.map(ins => formData.append('insurances[]', ins.id))
  formData.append('billing_insurance[insurance_qualifier]', insurance_qualifier)
  formData.append('billing_insurance[taxonomy_number]', taxonomy_number)
  // formData.append('billing_insurance[procedure_code]', procedure_code)
  formData.append('billing_insurance[penalty_for_cancelling]', penalty_for_cancelling)
  formData.append('billing_insurance[partial_penalty_for_cancelling]', partial_penalty_for_cancelling)
  formData.append('default_intake_session_length', default_intake_session_length)
  formData.append('intake_schedule_in_advance', intake_schedule_in_advance)
  formData.append('max_intakes_per_week', max_intakes_per_week)

  // for (const key in schedule_time_slot) {
  //   schedule_time_slot[key]
  // }
  for (const key in schedule_time_slot) {
    schedule_time_slot[key].array.filter(() => schedule_time_slot[key].open).map((val, index) => {
      formData.append(`schedule_time_slot[${key}][${index}][from]`, schedule_time_slot[key].array[index].from.value)
      formData.append(`schedule_time_slot[${key}][${index}][to]`, schedule_time_slot[key].array[index].to.value)
    })
  }
  // formData.append('billing_insurance[private_pay_rate]', private_pay_rate)
  // formData.append('billing_insurance[default_procedure_a1]', default_procedure_a1)
  // formData.append('billing_insurance[default_procedure_b1]', default_procedure_b1)
  // formData.append('billing_insurance[default_procedure_c1]', default_procedure_c1)
  // formData.append('billing_insurance[default_procedure_d1]', default_procedure_d1)

  // if (additional_procedures.length) {
  //   additional_procedures.forEach((procedure, index) => {
  //     formData.append(`billing_insurance[additional_procedures][${index}][procedure_code_a2]`, procedure?.procedure_code_a2 ?? '')
  //     formData.append(`billing_insurance[additional_procedures][${index}][private_pay_rate_a2]`, procedure?.private_pay_rate_a2 ?? '')
  //     formData.append(`billing_insurance[additional_procedures][${index}][default_procedure_a1]`, procedure?.default_procedure_a1 ?? '')
  //     formData.append(`billing_insurance[additional_procedures][${index}][default_procedure_b1]`, procedure?.default_procedure_b1 ?? '')
  //     formData.append(`billing_insurance[additional_procedures][${index}][default_procedure_c1]`, procedure?.default_procedure_c1 ?? '')
  //     formData.append(`billing_insurance[additional_procedures][${index}][default_procedure_d1]`, procedure?.default_procedure_d1 ?? '')
  //   })
  // } else {
  //   formData.append('billing_insurance[additional_procedures]', '')
  // }

  for (const pair of formData.entries()) {
    if (pair[1] === 'null') {
      formData.set(pair[0], '')
    }
  }

  return formData
}

export function getCookie(name) {
  // const value = `; ${document.cookie}`;
  // const parts = value.split(`; ${name}=`);
  // if (parts.length === 2) return parts.pop().split(';').shift();
  const auth = localStorage.getItem(name)
  return auth
}

export const doctorGenerateItemForm = (payload) => {
  const { data: {
    logo,
    avatar,
    name,
    default_service,
    slug,
    is_accept_new_patients,
    without_physical_address,
    city,
    zip_code,
    email,
    phone,
    street,
    state,
    longitude,
    latitude,
    states,
    cities,
    header_color,
    website,
    instagram_link,
    linkedin_link,
    intro_video_link,
    place_of_services,
    services,
    ages_comment,
    clients,
    languages,
    genders,
    accreditations,
    certifications,
    educations,
    is_notify_clients,
    default_treatment_session_length,
    default_evaluation_session_length,
    notify_number_of_hours,
    faqs,
    is_enabled_waiting_list,
    waiting_list,
    founder_story,
    parent_perspective,
    description,
    google_calendar_id,
    difference_title,
    difference,
    gallery,
    google_review_link,
    yelp_review_link,
    schedule,
    personal_info,
    insurances,
    procedure_codes,
    billing_insurance_info: {
      insurance_qualifier,
      taxonomy_number,
      // procedure_code,
      // private_pay_rate,
      // default_procedure_a1,
      // default_procedure_b1,
      // default_procedure_c1,
      // default_procedure_d1,
      // additional_procedures,
      penalty_for_cancelling,
      partial_penalty_for_cancelling
    },
    default_intake_session_length,
    intake_schedule_in_advance,
    max_intakes_per_week,
    schedule_time_slot,
    other_location_text,
    in_home_cities
  } } = payload
  const doctor: Provider = {
    main: {
      logo,
      avatar,
      name,
      default_service_id: default_service?.id ?? '',
      slug,
      is_accept_new_patients,
      without_physical_address,
      city,
      zip_code,
      email,
      phone,
      street,
      state,
      longitude,
      latitude,
      zipOrState: states.length ? 'states' : cities.length ? 'cities' : 'states',
      states,
      cities,
      header_color
    },
    website,
    instagram_link,
    linkedin_link,
    intro_video_link,
    place_of_services,
    services,
    ages_comment,
    languages,
    genders,
    accreditations,
    certifications,
    educations,
    is_notify_clients: is_notify_clients ? 'Yes' : 'No' ,
    default_treatment_session_length,
    default_evaluation_session_length,
    in_home_cities,
    other_location_text,
    notify_number_of_hours,
    faqs,
    is_enabled_waiting_list,
    waiting_list: waiting_list.map(wL => ({...wL, service_id: wL.service.id })),
    founder_story,
    parent_perspective_description: parent_perspective?.description ?? '',
    parent_perspective_name: parent_perspective?.name ?? '',
    parent_perspective_date: parent_perspective?.date ?? '',
    description,
    difference_title,
    difference,
    gallery,
    google_review_link,
    yelp_review_link,
    schedulingIsContactForm: Boolean(schedule),
    schedule_email: schedule?.data?.email ?? '',
    schedule_respond_within: schedule?.data?.respond_within ?? '',
    personalInfo: {
      first_name: personal_info?.first_name ?? '',
      last_name: personal_info?.last_name ?? '',
      middle_initial: personal_info?.middle_initial ?? '',
      gusto_name: personal_info?.gusto_name ?? '',
      birthday_at: personal_info?.birthday_at ?? '',
      preferences: personal_info?.preferences ?? '',
      phone: personal_info?.phone ?? '',
      email: personal_info?.email ?? '',
      address: personal_info?.address ?? '',
      city: personal_info?.city ?? '',
      state: personal_info?.state ?? '',
      zip_code: personal_info?.zip_code ?? '',
      npi_number: personal_info?.npi_number ?? '',
      license_state: personal_info?.license_state ?? '',
      license_number: personal_info?.license_number ?? '',
      license_expiration: personal_info.license_expiration,
      additional_licenses:  personal_info?.additional_licenses ?? [],
      caqh_number: personal_info?.caqh_number ?? '',
      caqh_username: personal_info?.caqh_username ?? '',
      caqh_password: personal_info?.caqh_password ?? '',
      malpractice_policy: personal_info?.malpractice_policy ?? '',
      malpractice_policy_start: personal_info.malpractice_policy_start,
      malpractice_policy_end: personal_info.malpractice_policy_end,
      ein: personal_info?.ein ?? '',
      clients: clients,
      google_calendar_id
    },
    billingInsuranceInfo: {
      procedure_codes: fixProcedureCodes(procedure_codes, insurances),
      insurance: insurances,
      insurance_qualifier,
      taxonomy_number,
      // procedure_code,
      // private_pay_rate: private_pay_rate ?? '',
      // default_procedure_a1,
      // default_procedure_b1,
      // default_procedure_c1,
      // default_procedure_d1,
      // additional_procedures: additional_procedures ?? [],
      penalty_for_cancelling,
      partial_penalty_for_cancelling,
    },
    default_intake_session_length,
    intake_schedule_in_advance,
    max_intakes_per_week,
    schedule_time_slot: schedule_time_slot ? generateScheduleTimeSlot(schedule_time_slot) : schedule_time_slot_data
  }
  console.log('doctor', doctor)
  return doctor
}

export const ifTimeInThePast = (item, from, isStart) => {
  const [hours, minutes] = item.value.split(':').map(Number);
  const [fromHours, fromMinutes] = from.value.split(':').map(Number);

  if (isStart && hours === fromHours && minutes === fromMinutes) {
    return true;
  }

  const isWithinRange = hours > fromHours;

  if (isWithinRange) {
    return true;
  }

  if (hours === fromHours) {
    return minutes > fromMinutes;
  }

  return false;
};

export const clientGenerateFormData = (payload) => {
  console.log('payload ====', payload)
  const {
    main: {
      status,
      inactive_reason,
      is_authorized,
      first_name,
      last_name,
      middle_initial,
      suffix,
      nickname,
      birthday_at,
      age,
      sex,
      gender_identity,
      street,
      city,
      state,
      zip_code,
      phone,
      email,
      languages,
      language,
      doctors,
      first_seen_at,
      last_seen_at,
      next_appointment_at,
      action_needed,
      url_action_needed,
      due_action_needed,
      notes,
      intake_forms_completed_at,
      intake_forms_sent_at,
    },
    billing_insurance_info : {
      is_insurance,
      is_credit,
      insurance_payer,
      client_relationship_insured,
      other_client_relationship_insured,
      first_name: bi_first_name,
      last_name: bi_last_name,
      middle_initial: bi_middle_initial,
      street: bi_street,
      city: bi_city,
      state: bi_state,
      zip_code: bi_zip_code,
      birthday_at: bi_birthday_at,
      sex: bi_sex,
      member_id,
      policy_group_number,
      plan_name,
      copay_amount,
      // deductible_amount,
      deductible_used,
      deductible_used_updated_at,
      visit_limit,
      is_no_visit_limit,
      visits_used,
      visits_used_updated_at,
      insurance_start_at,
      insurance_end_at,
      other_insurance_start_at,
      other_insurance_end_at,
      phone: bi_phone,
      coinsurance,
      other_coinsurance,
      // front_insurance_card,
      // back_insurance_card,
      is_covered_other_plan,
      is_covered_additional_plan,
      is_related_employment,
      is_related_auto_accident,
      auto_accident_state,
      is_related_other_accident,
      unable_work_from,
      unable_work_to,
      diagnoses,
      // default_diagnosis_pointer,
      is_outside_lab_charges,
      outside_lab_fees,
      default_place,
      procedure_code,
      number_of_units,
      // rate_per_unit,
      procedure_modifier_a1,
      procedure_modifier_b1,
      name_referring_provider,
      referring_provider_id,
      npi_referring_provider,
      prior_authorization_number,
      // prior_authorization_attachment,
      prior_authorization_start,
      prior_authorization_end,
      insurance_note,
      other_first_name,
      other_last_name,
      other_middle_initial,
      other_street,
      other_city,
      other_state,
      other_zip_code,
      other_birthday_at,
      other_sex,
      other_insurance_payer,
      other_member_id,
      other_policy_group_number,
      other_copay_amount,
      // other_deductible_amount,
      other_deductible_used,
      other_deductible_used_updated_at,
      other_visit_limit,
      other_is_no_visit_limit,
      other_visits_used,
      other_visits_used_updated_at,
      other_phone,
      // other_front_insurance_card,
      // other_back_insurance_card,
      additional_first_name,
      additional_last_name,
      additional_middle_initial,
      additional_street,
      additional_city,
      additional_state,
      additional_zip_code,
      additional_birthday_at,
      additional_sex,
      additional_insurance_payer,
      additional_member_id,
      additional_policy_group_number,
      additional_copay_amount,
      // additional_deductible_amount,
      additional_deductible_used,
      additional_deductible_used_updated_at,
      additional_visit_limit,
      additional_is_no_visit_limit,
      additional_visits_used,
      additional_visits_used_updated_at,
      additional_phone,
      // additional_front_insurance_card,
      // additional_back_insurance_card,
      next_insurance,
      other_next_insurance,
      stripe_id,
      virtual_coverage
    },
    contacts,
    documents,
  } = payload

  const formData = new FormData()
  formData.append('status', status)
  if (status === 'Inactive') {
    formData.append('inactive_reason', inactive_reason)
  } else {
    formData.append('inactive_reason', '')
  }
  // formData.append('is_authorized', is_authorized ? '1' : '0')
  formData.append('first_name', first_name)
  formData.append('last_name', last_name)
  formData.append('middle_initial', middle_initial)
  formData.append('suffix', suffix)
  formData.append('nickname', nickname)
  formData.append('birthday_at', birthday_at ? changeDate(birthday_at) : '')
  formData.append('age', age ? age : '')
  formData.append('sex', sex ? sex : '')
  formData.append('gender_identity', gender_identity)
  formData.append('intake_forms_completed_at', changeDate(intake_forms_completed_at))
  formData.append('intake_forms_sent_at', changeDate(intake_forms_sent_at))
  formData.append('street', street)
  formData.append('city', city)
  formData.append('state', state)
  formData.append('zip_code', zip_code)
  formData.append('phone', phone)
  formData.append('email', email)
  formData.append('stripe_id', stripe_id)
  languages && languages.map(language => formData.append('languages[]', language.id))
  formData.append('language', language)
  doctors.map(language => formData.append('doctors[]', language.id))
  formData.append('action_needed', action_needed)
  formData.append('url_action_needed', url_action_needed)
  formData.append('due_action_needed', changeDate(due_action_needed))
  formData.append('notes', notes)
  formData.append('billing_insurance[is_insurance]', is_insurance ? '1' : '0')
  formData.append('billing_insurance[is_credit]', is_credit ? '1' : '0')
  formData.append('billing_insurance[insurance_payer_id]', insurance_payer?.id ?? '')
  formData.append('billing_insurance[client_relationship_insured]', client_relationship_insured)
  formData.append('billing_insurance[first_name]', bi_first_name)
  formData.append('billing_insurance[last_name]', bi_last_name)
  formData.append('billing_insurance[middle_initial]', bi_middle_initial)
  formData.append('billing_insurance[street]', bi_street)
  formData.append('billing_insurance[city]', bi_city)
  formData.append('billing_insurance[state]', bi_state)
  formData.append('billing_insurance[zip_code]', bi_zip_code)
  formData.append('billing_insurance[birthday_at]', changeDate(bi_birthday_at))
  formData.append('billing_insurance[sex]', bi_sex)
  formData.append('billing_insurance[member_id]', member_id)
  formData.append('billing_insurance[policy_group_number]', policy_group_number)
  formData.append('billing_insurance[plan_name]', plan_name)
  formData.append('billing_insurance[copay_amount]', copay_amount)
  // formData.append('billing_insurance[deductible_amount]', deductible_amount)
  formData.append('billing_insurance[deductible_used]', deductible_used)
  formData.append('billing_insurance[insurance_start_at]', changeDate(insurance_start_at))
  formData.append('billing_insurance[insurance_end_at]', changeDate(insurance_end_at))
  formData.append('billing_insurance[visit_limit]', visit_limit)
  formData.append('billing_insurance[is_no_visit_limit]', is_no_visit_limit ? '1' : '0')
  formData.append('billing_insurance[visits_used]', visits_used)
  formData.append('billing_insurance[phone]', bi_phone)
  formData.append('billing_insurance[coinsurance]', coinsurance || 0)
  formData.append('billing_insurance[virtual_coverage]', virtual_coverage)

  // if (front_insurance_card && typeof front_insurance_card !== 'string') {
  //   formData.append('billing_insurance[front_insurance_card]', front_insurance_card)
  // } else if (!front_insurance_card) {
  //   formData.append('billing_insurance[front_insurance_card]', '')
  // }

  // if (back_insurance_card && typeof back_insurance_card !== 'string') {
  //   formData.append('billing_insurance[back_insurance_card]', back_insurance_card)
  // } else if (!back_insurance_card) {
  //   formData.append('billing_insurance[back_insurance_card]', '')
  // }

  formData.append('billing_insurance[is_covered_other_plan]', is_covered_other_plan ? '1' : '0')
  formData.append('billing_insurance[is_covered_additional_plan]', is_covered_additional_plan ? '1' : '0')
  formData.append('billing_insurance[is_related_employment]', is_related_employment ? '1' : '0')
  formData.append('billing_insurance[is_related_auto_accident]', is_related_auto_accident ? '1' : '0')
  formData.append('billing_insurance[auto_accident_state]', auto_accident_state)
  formData.append('billing_insurance[is_related_other_accident]', is_related_other_accident ? '1' : '0')
  formData.append('billing_insurance[unable_work_from]', changeDate(unable_work_from))
  formData.append('billing_insurance[unable_work_to]', changeDate(unable_work_to))
  diagnoses.map((id) => {
    formData.append(`billing_insurance[diagnoses][]`, id)
  })
  // formData.append('billing_insurance[default_diagnosis_pointer]', default_diagnosis_pointer && default_diagnosis_pointer.length ? default_diagnosis_pointer.sort().join(',') : null)
  formData.append('billing_insurance[is_outside_lab_charges]', is_outside_lab_charges ? '1' : '0')
  formData.append('billing_insurance[outside_lab_fees]', outside_lab_fees)
  formData.append('billing_insurance[default_place_id]', default_place?.id ?? '')
  formData.append('billing_insurance[procedure_code_id]', procedure_code?.id ?? '')
  formData.append('billing_insurance[number_of_units]', number_of_units)
  // formData.append('billing_insurance[rate_per_unit]', rate_per_unit)
  formData.append('billing_insurance[procedure_modifier_a1]', procedure_modifier_a1?.id ?? '')
  formData.append('billing_insurance[procedure_modifier_b1]', procedure_modifier_b1?.id ?? '')
  formData.append('billing_insurance[name_referring_provider]', name_referring_provider)
  formData.append('billing_insurance[referring_provider_id]', referring_provider_id)
  formData.append('billing_insurance[npi_referring_provider]', npi_referring_provider)
  formData.append('billing_insurance[prior_authorization_number]', prior_authorization_number)

  // if (prior_authorization_attachment && typeof prior_authorization_attachment !== 'string') {
  //   formData.append('billing_insurance[prior_authorization_attachment]', prior_authorization_attachment)
  // } else if (!prior_authorization_attachment) {
  //   formData.append('billing_insurance[prior_authorization_attachment]', '')
  // }
  formData.append('billing_insurance[prior_authorization_start]', changeDate(prior_authorization_start))
  formData.append('billing_insurance[prior_authorization_end]', changeDate(prior_authorization_end))
  formData.append('billing_insurance[insurance_note]', insurance_note)
  formData.append('billing_insurance[other_client_relationship_insured]', other_client_relationship_insured)
  formData.append('billing_insurance[other_first_name]', other_first_name)
  formData.append('billing_insurance[other_last_name]', other_last_name)
  formData.append('billing_insurance[other_middle_initial]', other_middle_initial)
  formData.append('billing_insurance[other_street]', other_street)
  formData.append('billing_insurance[other_city]', other_city)
  formData.append('billing_insurance[other_state]', other_state)
  formData.append('billing_insurance[other_zip_code]', other_zip_code)
  formData.append('billing_insurance[other_birthday_at]', changeDate(other_birthday_at))
  formData.append('billing_insurance[other_sex]', other_sex)
  formData.append('billing_insurance[other_insurance_payer_id]', other_insurance_payer?.id ?? '')
  formData.append('billing_insurance[other_member_id]', other_member_id)
  formData.append('billing_insurance[other_policy_group_number]', other_policy_group_number)
  formData.append('billing_insurance[other_copay_amount]', other_copay_amount)
  // formData.append('billing_insurance[other_deductible_amount]', other_deductible_amount)
  formData.append('billing_insurance[other_deductible_used]', other_deductible_used)
  formData.append('billing_insurance[other_insurance_start_at]', changeDate(other_insurance_start_at))
  formData.append('billing_insurance[other_insurance_end_at]', changeDate(other_insurance_end_at))
  formData.append('billing_insurance[other_visit_limit]', other_visit_limit)
  formData.append('billing_insurance[other_is_no_visit_limit]', other_is_no_visit_limit ? '1' : '0')
  formData.append('billing_insurance[other_visits_used]', other_visits_used)
  formData.append('billing_insurance[other_phone]', other_phone)
  formData.append('billing_insurance[other_coinsurance]', other_coinsurance)

  // if (other_front_insurance_card && typeof other_front_insurance_card !== 'string') {
  //   formData.append('billing_insurance[other_front_insurance_card]', other_front_insurance_card)
  // } else if (other_front_insurance_card) {
  //   formData.append('billing_insurance[other_front_insurance_card]', '')
  // }
  // if (other_back_insurance_card && typeof other_back_insurance_card !== 'string') {
  //   formData.append('billing_insurance[other_back_insurance_card]', other_back_insurance_card)
  // } else if (other_back_insurance_card) {
  //   formData.append('billing_insurance[other_back_insurance_card]', '')
  // }

  formData.append('billing_insurance[additional_first_name]', additional_first_name)
  formData.append('billing_insurance[additional_last_name]', additional_last_name)
  formData.append('billing_insurance[additional_middle_initial]', additional_middle_initial)
  formData.append('billing_insurance[additional_street]', additional_street)
  formData.append('billing_insurance[additional_city]', additional_city)
  formData.append('billing_insurance[additional_state]', additional_state)
  formData.append('billing_insurance[additional_zip_code]', additional_zip_code)
  formData.append('billing_insurance[additional_birthday_at]', changeDate(additional_birthday_at))
  formData.append('billing_insurance[additional_sex]', additional_sex)
  formData.append('billing_insurance[additional_insurance_payer_id]', additional_insurance_payer?.id ?? '')
  formData.append('billing_insurance[additional_member_id]', additional_member_id)
  formData.append('billing_insurance[additional_policy_group_number]', additional_policy_group_number)
  formData.append('billing_insurance[additional_copay_amount]', additional_copay_amount)
  // formData.append('billing_insurance[additional_deductible_amount]', additional_deductible_amount)
  formData.append('billing_insurance[additional_deductible_used]', additional_deductible_used)
  formData.append('billing_insurance[additional_visit_limit]', additional_visit_limit)
  formData.append('billing_insurance[additional_is_no_visit_limit]', additional_is_no_visit_limit ? '1' : '0')
  formData.append('billing_insurance[additional_visits_used]', additional_visits_used)
  formData.append('billing_insurance[additional_phone]', additional_phone)

  if (next_insurance) {
      formData.append('billing_insurance[next_insurance][client_relationship_insured]', next_insurance.client_relationship_insured)
      formData.append('billing_insurance[next_insurance][insurance_payer_id]', next_insurance.insurance_payer?.id ?? '')
      formData.append('billing_insurance[next_insurance][first_name]', next_insurance.first_name)
      formData.append('billing_insurance[next_insurance][last_name]', next_insurance.last_name)
      formData.append('billing_insurance[next_insurance][middle_initial]', next_insurance.middle_initial)
      formData.append('billing_insurance[next_insurance][street]', next_insurance.street)
      formData.append('billing_insurance[next_insurance][city]', next_insurance.city)
      formData.append('billing_insurance[next_insurance][state]', next_insurance.state)
      formData.append('billing_insurance[next_insurance][zip_code]', next_insurance.zip_code)
      formData.append('billing_insurance[next_insurance][birthday_at]', changeDate(next_insurance.birthday_at))
      formData.append('billing_insurance[next_insurance][sex]', next_insurance.sex)
      formData.append('billing_insurance[next_insurance][member_id]', next_insurance.member_id)
      formData.append('billing_insurance[next_insurance][policy_group_number]', next_insurance.policy_group_number)
      formData.append('billing_insurance[next_insurance][plan_name]', next_insurance.plan_name)
      formData.append('billing_insurance[next_insurance][copay_amount]', next_insurance.copay_amount)
      // formData.append('billing_insurance[next_insurance][deductible_amount]', next_insurance.deductible_amount)
      formData.append('billing_insurance[next_insurance][deductible_used]', next_insurance.deductible_used)
      formData.append('billing_insurance[next_insurance][deductible_used_updated_at]', changeDate(next_insurance.deductible_used_updated_at))
      formData.append('billing_insurance[next_insurance][visit_limit]', next_insurance.visit_limit)
      formData.append('billing_insurance[next_insurance][is_no_visit_limit]', next_insurance.is_no_visit_limit ? '1' : '0')
      formData.append('billing_insurance[next_insurance][visits_used]', next_insurance.visits_used)
      formData.append('billing_insurance[next_insurance][visits_used_updated_at]', changeDate(next_insurance.visits_used_updated_at))
      formData.append('billing_insurance[next_insurance][phone]', next_insurance.phone)
      formData.append('billing_insurance[next_insurance][coinsurance]', next_insurance?.coinsurance ?? 0)
      formData.append('billing_insurance[next_insurance][insurance_start_at]', changeDate(next_insurance.insurance_start_at))
      formData.append('billing_insurance[next_insurance][insurance_end_at]', changeDate(next_insurance.insurance_end_at))
  }
  if (other_next_insurance) {
      formData.append('billing_insurance[other_next_insurance][client_relationship_insured]', other_next_insurance.client_relationship_insured)
      formData.append('billing_insurance[other_next_insurance][insurance_payer_id]', other_next_insurance.insurance_payer?.id ?? '')
      formData.append('billing_insurance[other_next_insurance][first_name]', other_next_insurance.first_name)
      formData.append('billing_insurance[other_next_insurance][last_name]', other_next_insurance.last_name)
      formData.append('billing_insurance[other_next_insurance][middle_initial]', other_next_insurance.middle_initial)
      formData.append('billing_insurance[other_next_insurance][street]', other_next_insurance.street)
      formData.append('billing_insurance[other_next_insurance][city]', other_next_insurance.city)
      formData.append('billing_insurance[other_next_insurance][state]', other_next_insurance.state)
      formData.append('billing_insurance[other_next_insurance][zip_code]', other_next_insurance.zip_code)
      formData.append('billing_insurance[other_next_insurance][birthday_at]', changeDate(other_next_insurance.birthday_at))
      formData.append('billing_insurance[other_next_insurance][sex]', other_next_insurance.sex)
      formData.append('billing_insurance[other_next_insurance][member_id]', other_next_insurance.member_id)
      formData.append('billing_insurance[other_next_insurance][policy_group_number]', other_next_insurance.policy_group_number)
      formData.append('billing_insurance[other_next_insurance][plan_name]', other_next_insurance.plan_name)
      formData.append('billing_insurance[other_next_insurance][copay_amount]', other_next_insurance.copay_amount)
      // formData.append('billing_insurance[other_next_insurance][deductible_amount]', other_next_insurance.deductible_amount)
      formData.append('billing_insurance[other_next_insurance][deductible_used]', other_next_insurance.deductible_used)
      formData.append('billing_insurance[other_next_insurance][deductible_used_updated_at]', changeDate(other_next_insurance.deductible_used_updated_at))
      formData.append('billing_insurance[other_next_insurance][visit_limit]', other_next_insurance.visit_limit)
      formData.append('billing_insurance[other_next_insurance][is_no_visit_limit]', other_next_insurance.is_no_visit_limit ? '1' : '0')
      formData.append('billing_insurance[other_next_insurance][visits_used]', other_next_insurance.visits_used)
      formData.append('billing_insurance[other_next_insurance][visits_used_updated_at]', changeDate(other_next_insurance.visits_used_updated_at))
      formData.append('billing_insurance[other_next_insurance][phone]', other_next_insurance.phone)
      formData.append('billing_insurance[other_next_insurance][coinsurance]', other_next_insurance?.coinsurance ?? 0)
      formData.append('billing_insurance[other_next_insurance][insurance_start_at]', changeDate(other_next_insurance.insurance_start_at))
      formData.append('billing_insurance[other_next_insurance][insurance_end_at]', changeDate(other_next_insurance.insurance_end_at))
  }
  // if (additional_front_insurance_card && typeof additional_front_insurance_card !== 'string') {
  //   formData.append('billing_insurance[additional_front_insurance_card]', additional_front_insurance_card)
  // } else if (additional_front_insurance_card) {
  //   formData.append('billing_insurance[additional_front_insurance_card]', '')
  // }

  // if (additional_back_insurance_card && typeof additional_back_insurance_card !== 'string') {
  //   formData.append('billing_insurance[additional_back_insurance_card]', additional_back_insurance_card)
  // } else if (additional_back_insurance_card) {
  //   formData.append('billing_insurance[additional_back_insurance_card]', '')
  // }


  contacts.map((contact, index) => {
    formData.append(`contacts[${index}][id]`, contact.id)
    formData.append(`contacts[${index}][relationship_type]`, contact.relation_type)
    formData.append(`contacts[${index}][is_primary]`, contact.is_primary ? '1' : '0')
  })

  documents.map((document, index) => {
    console.log('document', document)
    if (document.id) {
      formData.append(`documents[${index}][id]`, document.id)
    }
    formData.append(`documents[${index}][name]`, document.name)
    formData.append(`documents[${index}][show_provider]`, document.show_provider.toString() === 'true' ? '1' : '0')
    formData.append(`documents[${index}][file]`, document.file)
  })

  for (const pair of formData.entries()) {
    console.log(pair)
    if (pair[1] === 'null') {
      formData.set(pair[0], '')
    }
  }
  return formData
}

export const clientGenerateItemForm = (payload) => {
  const {
    data : {
      id,
      card,
      status,
      inactive_reason,
      is_authorized,
      first_name,
      last_name,
      middle_initial,
      suffix,
      nickname,
      birthday_at,
      age,
      sex,
      years_old,
      gender_identity,
      intake_forms_completed_at,
      intake_forms_sent_at,
      languages,
      language,
      doctors,
      street,
      city,
      state,
      zip_code,
      phone,
      email,
      first_seen_at,
      last_seen_at,
      next_appointment_at,
      notes,
      action_needed,
      url_action_needed,
      due_action_needed,
      billing_insurance_info,
      contacts,
      issues,
      documents,
      stripe_id
    }
  } = payload

  billing_insurance_info.diagnoses = billing_insurance_info.diagnoses.map(val => val.id)
  billing_insurance_info.card = card
  billing_insurance_info.stripe_id = stripe_id
  billing_insurance_info.birthday_at = billing_insurance_info.birthday_at ? parseISO(billing_insurance_info.birthday_at) : ''
  billing_insurance_info.other_birthday_at = billing_insurance_info.other_birthday_at ? parseISO(billing_insurance_info.other_birthday_at) : ''
  billing_insurance_info.additional_birthday_at = billing_insurance_info.additional_birthday_at ? parseISO(billing_insurance_info.additional_birthday_at) : ''
  if (billing_insurance_info.next_insurance) {
    billing_insurance_info.next_insurance.birthday_at = billing_insurance_info?.next_insurance.birthday_at ? parseISO(billing_insurance_info?.next_insurance?.birthday_at) : ''
  }
  const client: Client = {
    main: {
      account_number: id,
      status,
      inactive_reason,
      is_authorized,
      first_name,
      last_name,
      middle_initial,
      suffix,
      nickname,
      birthday_at,
      age,
      sex,
      years_old,
      gender_identity,
      intake_forms_completed_at,
      intake_forms_sent_at,
      languages,
      language,
      doctors,
      street,
      city,
      state,
      zip_code,
      phone,
      email,
      first_seen_at,
      last_seen_at,
      next_appointment_at,
      notes,
      action_needed,
      url_action_needed,
      due_action_needed,
    },
    billing_insurance_info,
    contacts,
    issues,
    documents
  }
  client.main.birthday_at = client.main.birthday_at ? `${client.main.birthday_at} 00:00:00` : null
  return client
}

export const issueReasonName = (id) => {
  return issueReasonsList.find(val => val.id === Number(id))?.name ?? ''
}
// const PTTimeZone = 'PST';
// const time = format(new Date(), 'yyyy-MM-dd H:mm:ss')
// const t2 = zonedTimeToUtc(time, 'America/Los_Angeles');
// const diff = differenceInHours(t2, new Date(time))
// const diffSec = diff * 60 * 60
// export const toTimeStampPTZone = (date) => {
  // let timestamp = new Date(date).getTime() / 1000
  // if (diff > 0) {
  //   timestamp = timestamp + diffSec
  // } else {
  //   timestamp = timestamp - Math.abs(diffSec)
  // }
  // return timestamp
// }

// export const toPTZoneFromServer = (date) => {
//   let timestamp = date
//   if (diff < 0) {
//     timestamp = timestamp + diffSec
//   } else {
//     timestamp = timestamp - Math.abs(diffSec)
//   }
//   return new Date(timestamp * 1000)
// }

export function generatePasswordFunc() {
  const lowercaseLetters = 'abcdefghijklmnopqrstuvwxyz';
  const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const numbers = '0123456789';

  // Generate a random lowercase letter
  const randomLowercaseLetter = lowercaseLetters.charAt(Math.floor(Math.random() * lowercaseLetters.length));

  // Generate a random uppercase letter
  const randomUppercaseLetter = uppercaseLetters.charAt(Math.floor(Math.random() * uppercaseLetters.length));

  // Generate a random number
  const randomNumber = numbers.charAt(Math.floor(Math.random() * numbers.length));

  // Generate remaining characters
  const remainingCharacters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let password = '';
  for (let i = 0; i < 5; i++) {
    password += remainingCharacters.charAt(Math.floor(Math.random() * remainingCharacters.length));
  }

  // Ensure that the password contains at least 1 letter, 1 number, and 1 uppercase letter
  const passwordArray = [randomLowercaseLetter, randomUppercaseLetter, randomNumber, ...password];
  password = passwordArray.sort(() => Math.random() - 0.5).join('');

  return password;
}


export function passwordRegex(ref, msg) {
  return this.test({
    name: 'passwordRegex',
    exclusive: false,
    message:
      msg ||
      'One letter, One number, One upper case letter, Eight total characters',
    // message: msg || 'Confirm email must be the same as New email',
    params: {
      reference: ref.path,
    },
    test: function (value) {
      return passwordRegEx.pattern.test(value);
    },
  });
}

export function humanFileSize(bytes, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
    );

  return bytes.toFixed(dp) + ' ' + units[u];
}

const fixProcedureCodes = (procedure_codes, insurances) => {
  console.log('procedure_codes', procedure_codes)
  console.log('insurances', insurances)
  return procedure_codes.map(procedure_code => {
    let { payout, charge } = procedure_code
    // if (payout.length && !charge.length) {
    //   charge = payout.map(val => ({ ...val, sum: null }))
    // }
    // fix backend bug
    const payoutWithInsurance = insurances.map(insurance => ({ id: insurance.id, sum: payout.find(p => p.id == insurance.id)?.sum ?? null }))
    const chargeWithInsurance = insurances.map(insurance => ({ id: insurance.id, sum: charge.find(p => p.id == insurance.id)?.sum ?? null }))

    return {
      ...procedure_code,
      payout: payoutWithInsurance,
      charge: chargeWithInsurance,
      // max_charge,
    }
  })
}

export const prepareFormDataSubmittedByProvider = (data, type) => {
  const formData = new FormData();
  formData.append("start_at", changeDate(data.start_at));
  formData.append("end_at", changeDate(data.end_at));
  data.diagnoses.map((id) => formData.append("diagnoses[]", id));
  data.procedure_codes.map((procedure_code, index) => {
    formData.append(`procedure_codes[${index}][id]`, procedure_code.id.id);
    formData.append(`procedure_codes[${index}][modifier_a]`, procedure_code?.modifier_a ?? '');
    formData.append(`procedure_codes[${index}][modifier_b]`, procedure_code?.modifier_b ?? '');
    formData.append(`procedure_codes[${index}][units]`, procedure_code.units);
    formData.append(
      `procedure_codes[${index}][payout]`,
      parseInt(procedure_code.payout) >= 0 ? procedure_code.payout : ""
    );
    formData.append(
      `procedure_codes[${index}][charge]`,
      parseInt(procedure_code?.charge) >= 0 ? procedure_code.charge : ""
    );
    formData.append(
      `procedure_codes[${index}][max_charge]`,
      parseInt(procedure_code?.max_charge) >= 0
        ? procedure_code.max_charge
        : ""
    );
  });
  formData.append("is_insurance", data.is_insurance ? "1" : "0");
  formData.append(
    "insurance_charge",
    parseInt(data.charge_data.insurance_charge) >= 0
      ? data.charge_data.insurance_charge
      : ""
  );
  formData.append(
    "patient_charge",
    parseInt(data.charge_data.patient_charge) >= 0
      ? data.charge_data.patient_charge
      : ""
  );
  // formData.append(
  //   "amount_expected_insurance",
  //   parseInt(data.charge_data.amount_expected_insurance) >= 0
  //     ? data.charge_data.amount_expected_insurance
  //     : ""
  // );
  formData.append("estimated_deductible_remaining", data.charge_data?.estimated_deductible_remaining ??  '');
  formData.append(
    "client_insurance_note",
    data.client.billing_insurance_info.insurance_note
  );
  if (type === 0) {
    data.soap.attachments.forEach((file, index) => {
      formData.append(`soap[attachments][${index}][name]`, file.name);
      formData.append(`soap[attachments][${index}][file]`, file.file);
    });
  } else {
    formData.append(`soap[goals]`, data.soap.goals);
    formData.append(`soap[subjective]`, data.soap.subjective);
    formData.append(`soap[objective]`, data.soap.objective);
    formData.append(`soap[assessment]`, data.soap.assessment);
    formData.append(`soap[plan]`, data.soap.plan);
    formData.append(`soap[parent_education]`, data.soap.parent_education);
  }
  formData.append("text", data?.follow_up_note ?? '');
  return formData
}

export const prepareFormDataCancelledWithFee = (data) => {
  const formData = new FormData()
  formData.append("start_at", changeDate(data.start_at));
  formData.append("end_at", changeDate(data.end_at));
  data.procedure_codes.map((procedure_code, index) => {
    formData.append(`procedure_codes[${index}][id]`, procedure_code.id.id);
    formData.append(`procedure_codes[${index}][modifier_a]`, procedure_code?.modifier_a ?? '');
    formData.append(`procedure_codes[${index}][modifier_b]`, procedure_code?.modifier_b ?? '');
    formData.append(`procedure_codes[${index}][units]`, procedure_code.units);
    formData.append(
      `procedure_codes[${index}][payout]`,
      parseInt(procedure_code.payout) >= 0 ? procedure_code.payout : ""
    );
    formData.append(
      `procedure_codes[${index}][charge]`,
      parseInt(procedure_code?.charge) >= 0 ? procedure_code.charge : ""
    );
    formData.append(
      `procedure_codes[${index}][max_charge]`,
      parseInt(procedure_code?.max_charge) >= 0
        ? procedure_code.max_charge
        : ""
    );
  });
  formData.append("is_insurance", data.is_insurance ? "1" : "0");
  formData.append("patient_charge", parseInt(data.cancelled_charge_data.patient_charge) >= 0 ? data.cancelled_charge_data.patient_charge : "" );
  formData.append("cancelled_payout", parseInt(data.cancelled_charge_data.payout) >= 0 ? data.cancelled_charge_data.payout : "" );
  return formData
}